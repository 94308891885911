/* eslint-disable react/prop-types */
import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getProposals } from 'src/api';
import { Proposal } from 'src/api/types';
import ErrorTableRow from 'src/components/Table//ErrorTableRow';
import LoadingTableRow from 'src/components/Table//LoadingTableRow';
import ProposalTableRow, { ProposalTableHead } from './ProposalTableRow';

const ProposalTable = ({ search, status }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [proposals, setProposals] = useState<Proposal[]>();
  const [error, setError] = useState<Error>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      const skip = page * rowsPerPage;

      const { data, total, error } = await getProposals(token, skip, rowsPerPage, status, search);

      if (error) {
        setError(error);
      }
      setProposals(data);
      setCount(total);
    };

    setLoading(true);
    fetchData();
    setLoading(false);
  }, [loading, getAccessTokenSilently, page, rowsPerPage, search]);

  return (
    <>
      <TableContainer>
        <Table>
          <ProposalTableHead status={status} />
          <TableBody>
            <LoadingTableRow loading={loading} />
            <ErrorTableRow error={error} />
            {proposals &&
              proposals.map((row) => <ProposalTableRow key={row._id} row={row} status={status} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ProposalTable;
