/* eslint-disable react/prop-types */
import { TableRow, TableCell, Typography, TableHead, Button } from '@mui/material';
import { ProposalStatus } from 'src/api/types';
import { shortenAddr } from 'src/utils';

import { truncateString } from 'src/utils/stringUtils';
import ViewProposalButton from './ViewProposalButton';
import ReviewProposalButton from './ReviewProposalButton';
import ExecuteProposalButton from './ExecuteProposalButton';
import UpdateOperatedReasonButton from './UpdateOperatedReasonButton';

export const ProposalTableHead = ({ status }) => (
  <TableHead>
    <TableRow>
      <TableCell>Title</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Submitter</TableCell>
      {status !== ProposalStatus.PENDING && <TableCell>Snapshot Block Number ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Start Block Number ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>End Block Number ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Pass condition ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Who reviewed ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Extra payment asset Id ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Extra payment amount ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Who executed ?</TableCell>}
      {status !== ProposalStatus.PENDING && <TableCell>Executed transaction ?</TableCell>}
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
);

const ProposalTableRow = ({ row, status }) => {
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell align="left">
          <Typography display="block">{row.title}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" display="block">
            {row.type}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" display="block">
            {truncateString(row.description.replace(/<[^>]*>/g, ''), 20)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" display="block">
            {row.status}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" display="block">
            {shortenAddr(row.walletAddress)}
          </Typography>
        </TableCell>
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="button" display="block">
              {row.snapshotBlockNumber}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.startBlock}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.endBlock}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.passConditions}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.votingProposalOperation?.operatorEmail}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.extraPayment?.paymentAssetId}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.extraPayment?.amount}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.executingProposalOperation?.operatorEmail}
            </Typography>
          </TableCell>
        )}
        {status !== ProposalStatus.PENDING && (
          <TableCell align="center">
            <Typography variant="body1" display="block">
              {row.proposalHash}
            </Typography>
          </TableCell>
        )}
        <TableCell align="center">
          <ViewProposalButton proposal={row} />
          {row.status === ProposalStatus.PENDING && <ReviewProposalButton proposal={row} />}
          {row.status === ProposalStatus.READY_FOR_EXECUTION && (
            <ExecuteProposalButton proposal={row} />
          )}
          {row.status === ProposalStatus.ADMIN_DECLINED && (
            <UpdateOperatedReasonButton proposal={row} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProposalTableRow;
